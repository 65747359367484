import { useBreakpointValue } from "@chakra-ui/react";
import { graphql, PageProps } from "gatsby";
import React from "react";
import Page from "../components/Page";
import { PageTitle } from "../components/PageTitle";
import SEO from "../components/SEO";
import { YouTubeVideo } from "../components/YouTubeVideo";
import { PrismicMedia } from "../prismic-types";
import { innerHtmlFromPrismicRaw } from "../util";

type QueryData = { prismicMedia: PrismicMedia };

export const query = graphql`
  {
    prismicMedia {
      data {
        page_title {
          text
        }
        body {
          ... on PrismicMediaBodyYoutubeVideo {
            id
            primary {
              blurb {
                raw
              }
              date
              title {
                text
              }
              youtube_url {
                url
              }
            }
          }
        }
        order_by_date
      }
    }
  }
`;

const MediaPage: React.FC<PageProps<QueryData>> = ({ data }) => {
  const pageTitle = data.prismicMedia.data?.page_title?.text;
  const orderByDate = data.prismicMedia.data?.order_by_date ?? false;

  const videos = data.prismicMedia.data?.body?.map((v) => {
    return {
      title: v?.primary?.title?.text,
      date: v?.primary?.date,
      url: v?.primary?.youtube_url?.url,
      blurb: v?.primary?.blurb?.raw
    };
  });

  if (orderByDate) {
    videos?.sort((a, b) => (a.date > b.date ? -1 : 1));
  }

  // Stacked in sm, md, then not
  const stacked = useBreakpointValue([true, true, false]);

  return (
    <Page>
      <SEO title={pageTitle ?? "Media"} />
      <PageTitle>{pageTitle ?? "Media"}</PageTitle>
      {videos?.map((v, idx) => {
        const blurbs = (Array.isArray(v.blurb) ? v.blurb : [v.blurb]).map((b) =>
          innerHtmlFromPrismicRaw(b)
        );
        return (
          <YouTubeVideo
            key={`media-yt-${idx}`}
            title={v.title || ""}
            date={v.date || ""}
            url={v.url || ""}
            blurb={blurbs}
            mirrored={stacked ? false : idx % 2 == 0}
          />
        );
      })}
    </Page>
  );
};

export default MediaPage;
